import React, { Component } from 'react';
import './styles/Loader.css';

export default class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "Setting things up for you"
        }
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <div id="loaderWrapper">
                {/* <h2 id='loader-tutorial'>
                    
                    {this.state.text}
                </h2> */}
                {/* <div className="spinner2">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div> */}
            </div>
        )
    }
}